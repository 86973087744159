<template>
  <v-container class="container-page">
    <v-card class="mt-0 transparent">
      <v-toolbar class="toolbar" color="#F7F8FC" flat>
        <h2 class="font-weight-bold display-1">
          Campanhas
        </h2>
        <v-spacer />
        <v-text-field
          v-model="search"
          class="search-input"
          label="Pesquise aqui"
          prepend-inner-icon="mdi-magnify"
          solo
          dense
          flat
          clearable
          hide-details />
        <v-btn class="ml-2" color="primary" :to="{ name: 'EventNew' }" depressed>
          Adicionar campanha
        </v-btn>
      </v-toolbar>

      <v-card-text class="px-0 py-0 campaign-list">
        <template v-if="loading">
          <loading class="absolute-center" />
        </template>

        <template v-else>
          <perfect-scrollbar :options="options">
            <v-layout
              row
              wrap>
              <v-flex
                v-for="item of filteredEvents"
                :key="item.id"
                xs12
                md3
                sm12>
                <v-card
                  class="campaign-item ma-2"
                  :to="{ name: 'EventInfo', params: { eventId: item.id } }"
                  flat
                  ripple>
                  <v-card-title>{{ item.name }} </v-card-title>
                  <v-card-subtitle>{{ $t('event.type.' + item.type) }} - {{ $t('event.procedure.' + item.procedure) }}</v-card-subtitle>
                  <v-divider class="mx-4" />
                  <v-card-text>
                    <v-list dense flat>
                      <v-list-item class="pa-0">
                        <v-icon small color="primary" :disabled="!item.proposal.has">
                          check_circle
                        </v-icon>
                        <span>Proposta Initial</span>
                      </v-list-item>
                      <v-list-item class="pa-0">
                        <v-icon small color="primary" :disabled="!item.negotiation.has">
                          check_circle
                        </v-icon>
                        <span>Negociação</span>
                      </v-list-item>
                      <v-list-item class="pa-0">
                        <v-icon small color="primary" :disabled="!item.mediators.has">
                          check_circle
                        </v-icon>
                        <span>Mediação</span>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions>
                    <v-chip class="ml-2 font-weight-bold" small :disabled="!item.email.send">
                      Email
                    </v-chip>
                    <v-chip class="ml-2 font-weight-bold" small :disabled="!item.sms.send">
                      Sms
                    </v-chip>
                    <v-chip class="ml-2 font-weight-bold" small :disabled="!item.letter.send">
                      Carta
                    </v-chip>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </perfect-scrollbar>
        </template>
      </v-card-text>
    </v-card>
    <transition
      name="slide"
      mode="out-in"
      appear>
      <router-view />
    </transition>
  </v-container>
</template>

<script>
  const AppList = () => import('@/components/ui/List')
  const LabelIcon = () => import('@/components/ui/LabelIcon')
  const Loading = () => import('@/components/ui/Loading')
  const EmptyState = () => import('@/components/ui/EmptyState')
  const Row = () => import('./Row')
  import { mapGetters } from 'vuex'

  export default {
    components: {
      AppList,
      Row,
      Loading,
      LabelIcon,
      EmptyState
    },
    data: () => ({
      options: { suppressScrollX: true, wheelPropagation: false },
      eventId: null,
      search: '',
      headers: [
        { text: 'Nome da campanha', value: 'name', class: 'checked-action', sortable: false },
        { text: 'Proposta Inicial', value: 'proposal', align: 'center', sortable: false },
        { text: 'Negociação', value: 'negotiation', align: 'center', sortable: false },
        { text: 'Mediação', value: 'mediation', align: 'center', sortable: false },
        { text: 'Comunicação', value: 'communication', align: 'center', sortable: false }
      ],
      pagination: {
        state: sessionStorage.getItem('EventPaginationState') || '',
        search: sessionStorage.getItem('EventPaginationSearch') || '',
        sortBy: [sessionStorage.getItem('EventPaginationSortBy') || 'code'],
        sortDesc: [JSON.parse(sessionStorage.getItem('EventPaginationDescending') || true)],
        page: parseInt(sessionStorage.getItem('EventPaginationPage') || 1),
        rowsPerPage: parseInt(sessionStorage.getItem('EventPaginationRowsPerPage') || 12),
        rowsPerPageItems: [12, 25, 50, 100]
      }
    }),
    computed: {
      ...mapGetters({
        events: 'events/list',
        loading: 'events/listLoading',
        totalCount: 'events/totalCount'
      }),
      filteredEvents () {
        if(this.search !== '' && this.search !== null) {
          return this.events.filter(event => event.name.toLowerCase().includes(this.search.toLowerCase()))
        }
        return this.events
      }
    },
    watch: {
      pagination: {
        handler (pagination) {},
        deep: true
      },
    },
    mounted () {
      this.setEventId()
    },
    beforeMount () {
      this.paginate(this.pagination)
    },
    methods: {
      selectEvent (item) {
        this.eventId = item.id
        this.$router.push({ name: 'EventGeneral', params: { eventId: item.id } })
      },
      resetEventId () {
        this.eventId = null
      },
      setEventId () {
        this.eventId = this.$route.params.eventId
      },
      changeRouter () {
        this.$route.name === 'Events' ? this.resetEventId() : this.setEventId()
      },
      paginate (pagination) {
        this.$store.dispatch('events/getList', pagination)
        this.pagination = pagination
      }
    }
  }
</script>

<style lang="sass">
  .table--selected
    background: #eee
  .campaign-item
    .v-list-item
      min-height: 28px
      font-weight: bold
      .v-icon
        margin-right: 5px
      .v-icon--disabled ~ span
        color: rgba(0, 0, 0, 0.38)
  .campaign-list
    .ps
      margin-top: 0px
      height: calc(100vh - 90px)
</style>
